import { put, call, take, fork, select, delay } from 'redux-saga/effects';
import moment from 'moment';

import {
  RecordHistoryAction,
  SET_TODAY_RECORD,
  FETCH_TODAY_RECORD,
  FETCH_TODAY_RECORD_SUCCESS,
  FETCH_TODAY_RECORD_FAILURE,
  RECORD_HISTORY,
  RECORD_HISTORY_SUCCESS,
  RECORD_HISTORY_FAILURE,
  GetRecordsAction,
  RecordOfDay,
  GET_RECORDS,
  SET_PREV_RECORDS,
  SetPrevRecordsAction,
  GET_RECORDS_SUCCESS,
  GET_RECORDS_FAILURE,
  FailureAction,
  SetHrRecordAction
} from '../module/hr';
import HrAPI from 'lib/api/hr';
import { State } from 'store';
import { UserState } from 'store/module/user';
import { HrType } from 'entity/Hr';
import { getRecordOfDayFromResponse, getRecordMapFromArray } from 'lib/helper/hr';

const getUser = (state: State): UserState => state.user;

function* getTodayRecord() {
  try {
    const user: UserState = yield select(getUser);
    const { body, statusCode } = yield call(
      HrAPI.fetchRecordsByDate,
      user.currentUser.uid,
      moment().format("YYYYMMDD")
    );
    let todayRecords: RecordOfDay;

    yield delay(100);

    todayRecords = body.records[0]
      ? getRecordOfDayFromResponse({
          attendance: body.records[0].attendance,
          leave: body.records[0].leave
        })
      : {
          attendance: undefined,
          leave: undefined
        };

    if (statusCode === 200) {
      const prevRecordMap: Map<string, RecordOfDay> = getRecordMapFromArray([todayRecords]);
      const setPrevRecordsAction: SetPrevRecordsAction = {
        type: SET_PREV_RECORDS,
        payload: {
          type: "date",
          prevRecordMap
        }
      };

      const setTodayRecordAction: SetHrRecordAction = {
        type: SET_TODAY_RECORD,
        payload: {
          todayRecords
        }
      };

      yield put(setTodayRecordAction);
      yield put({ type: FETCH_TODAY_RECORD_SUCCESS });
      yield put(setPrevRecordsAction);
    } else {
      const failureAction: FailureAction = {
        type: FETCH_TODAY_RECORD_FAILURE,
        payload: {
          errorMessage: body.returnMessage
        }
      };

      yield put(failureAction);
    }
  } catch (error) {
    console.error("[saga] Error hr / getTodayRecord\n", error);

    const failureAction: FailureAction = {
      type: FETCH_TODAY_RECORD_FAILURE,
      payload: {
        errorMessage: error.message
      }
    };
    yield put(failureAction);
  }
}

function* getRecords(date: moment.Moment, type: "date" | "month") {
  try {
    const { currentUser: user }: UserState = yield select(getUser);
    const params: [(...any) => Promise<any>, string, string] =
      type === "date"
        ? [HrAPI.fetchRecordsByDate, user.uid, date.format("YYYYMMDD")]
        : [HrAPI.fetchRecordsByMonth, user.uid, date.format("YYYYMM")];

    const data = yield call(params[0], params[1], params[2]);

    const { body, statusCode } = data;

    if (statusCode !== 200) throw Error(body.returnMessage);

    const rod: RecordOfDay[] = body.records.map(record =>
      getRecordOfDayFromResponse(record)
    );
    const prevRecordMap: Map<string, RecordOfDay> = getRecordMapFromArray(rod);
    const month = type === "month" && date.format("YYYYMM");
    const setPrevRecordsAction: SetPrevRecordsAction = {
      type: SET_PREV_RECORDS,
      payload: {
        type,
        month,
        prevRecordMap
      }
    };

    yield put(setPrevRecordsAction);
    yield put({ type: GET_RECORDS_SUCCESS });
  } catch (e) {
    console.error("[saga] Error hr / getRecords\n", e);

    const getRecordFailureAction: FailureAction = {
      type: GET_RECORDS_FAILURE,
      payload: {
        errorMessage: typeof e === "string" ? e : e.message
      }
    };
    yield put(getRecordFailureAction);
  }
}

function* recordHistory(hrType: HrType) {
  const { currentUser: user }: UserState = yield select(getUser);
  const { body, statusCode } = yield call(HrAPI.recordHistory, {
    hrType,
    email: user.email,
    userId: user.uid,
    name: user.displayName
  });
  const { returnMessage } = body;

  if (statusCode === 200) {
    yield put({
      type: RECORD_HISTORY_SUCCESS,
      payload: {
        successMessage: returnMessage
      }
    });
    yield put({
      type: FETCH_TODAY_RECORD
    });
    yield put({
      type: GET_RECORDS,
      payload: {
        date: moment(new Date()),
        type: "date"
      }
    });
  } else {
    const failureAction: FailureAction = {
      type: RECORD_HISTORY_FAILURE,
      payload: {
        errorMessage: returnMessage
      }
    };
    yield put(failureAction);
  }
}

export function* watchRequestRecordsByDate() {
  while (true) {
    const { payload }: GetRecordsAction = yield take(GET_RECORDS);
    yield fork(getRecords, payload.date, payload.type);
  }
}

export function* watchRequestFetchTodayRecord() {
  while (true) {
    yield take(FETCH_TODAY_RECORD);
    yield fork(getTodayRecord);
  }
}

export function* watchRecordHistory() {
  while (true) {
    const { payload }: RecordHistoryAction = yield take(RECORD_HISTORY);
    yield fork(recordHistory, payload.hrType);
  }
}
