import React from 'react';
import cn from 'classnames';
import styles from './Alert.module.scss';

interface AlertProps {
  message: string;
  show: boolean;
  type?: 'warning' | 'success' | 'error';
}

const Alert: React.FC<AlertProps> = ({ message, show }) => {
  return (
    <div className={cn(styles.alert, {
      [styles.show]: show
    })}>
      <p className={styles.message}>
        {message}
      </p>
    </div>
  )
}

export default Alert;