import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import styles from 'styles/global.module.scss';
import Alert from 'components/common/Alert';
import { State } from 'store';

type Props = {
  modalOpened: boolean;
  pageOpened: boolean;
  isOffline: boolean;
}

const GlobalContainer: React.FC<Props> = ({ children, modalOpened, pageOpened, isOffline }) => {
  useEffect(() => {
    if (pageOpened) {
      document.getElementById('root').style.transform = 'transform(-100px)';
    } else {
      document.getElementById('root').style.transform = 'transform(0px)';
    }
  }, [pageOpened]);

  return (
    <div className={styles.global}>
      {children}
      <Alert show={isOffline} message='흠... 네트워크 연결 상태를 확인해주세요' />
    </div>
  )
}

export default connect(
  (state: State) => ({
    modalOpened: state.global.modalOpened,
    pageOpened: state.global.pageOpened,
    isOffline: state.global.isOffline,
  })
)(GlobalContainer);