import { ActionType } from '@redux-saga/types';
import { createAction, handleActions } from 'redux-actions';
import { ActionCreator } from 'redux';
import { User } from 'entity/user';

/**
 * Actions
 */

const SET_USER: ActionType = 'user/SET_USER';

export type UserActionCreators = {
  setUser: ActionCreator<any>,
}

export const actionCreators: UserActionCreators = {
  setUser: createAction(SET_USER),
}

/**
 * Type / Utils
 */

export interface UserState {
  currentUser: User;
}

export interface SetUserAction {
  type: string | number | symbol,
  payload: {
    user: User,
  }
}

export function isSameUser(user1: User, user2: User) {
  return (user1.displayName === user2.displayName) &&
  (user1.email === user2.email) &&
  (user1.uid === user2.uid) &&
  (user1.photoUrl === user2.photoUrl)
}

export function getUserFromFBUser(user: firebase.User): User {
  return {
    uid: user.providerData[0].uid,
    email: user.email,
    displayName: user.displayName,
    photoUrl: user.photoURL,  
  }
}

/**
 * Reducers
 */

const initialState: UserState = {
  currentUser: {
    uid: '',
    email: '',
    displayName: '',
    photoUrl: '',
  }
}

export default handleActions({
  [SET_USER]: (state: UserState, action: SetUserAction): UserState => {
    const { user } = action.payload;
    return ({
      ...state,
      currentUser: user,
  })},
}, initialState)