import React, { Component } from 'react';
import Modal from 'react-modal';
import { Switch, Route } from 'react-router-dom';
import * as serviceWorker from '../serviceWorker';

import AuthContainer from 'container/auth/AuthContainer';
import GlobalContainer from 'container/global/GlobalContainer';
import { Main } from 'pages';
import { GlobalActions } from 'store/actionCreators';

class App extends Component {
  componentDidMount() {
    Modal.setAppElement('body');
    serviceWorker.register({
      onUpdate: () => {
        GlobalActions.setUpdateAvailable();
      }
    });

    window.addEventListener('load', this.onLoad);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.onLoad);
    window.removeEventListener('online', this.onNetworkStatusChange);
    window.removeEventListener('offline', this.onNetworkStatusChange);
  }

  onNetworkStatusChange = () => {
    if (navigator.onLine) {
      GlobalActions.setNetworkOnline();
    } else {
      GlobalActions.setNetworkOffline();
    }
  };

  onLoad = () => {
    this.onNetworkStatusChange();

    window.addEventListener('online', this.onNetworkStatusChange);
    window.addEventListener('offline', this.onNetworkStatusChange);
  };

  render() {
    return (
      <GlobalContainer>
        <Switch>
          <AuthContainer>
            <Route path='/' component={Main} />
          </AuthContainer>
        </Switch>
      </GlobalContainer>
    );
  }
}

export default App;
