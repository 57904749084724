import { ActionType } from "@redux-saga/types";
import { createAction, handleActions } from "redux-actions";
import { ActionCreator } from 'redux';

/**
 * Actions
 */

 const MODAL_OPENED: ActionType = 'global/MODAL_OPENED';
 const MODAL_CLOSED: ActionType = 'global/MODAL_CLOSED';

 const PAGE_OPENED: ActionType = 'global/PAGE_OPENED';
 const PAGE_CLOSED: ActionType = 'global/PAGE_CLOSED';

 const SET_UPDATE_AVAILABLE: ActionType = 'global/SET_UPDATE_AVAILABLE';
 const SET_NETWORK_OFFLINE: ActionType = 'global/SET_NETWORK_OFFLINE';
 const SET_NETWORK_ONLINE: ActionType = 'global/SET_NETWORK_ONLINE';


 export type GlobalActionCreators = {
   modalOpened: ActionCreator<any>;
   modalClosed: ActionCreator<any>;
   pageOpened: ActionCreator<any>;
   pageClosed: ActionCreator<any>;
   setUpdateAvailable: ActionCreator<any>;
   setNetworkOffline: ActionCreator<any>;
   setNetworkOnline: ActionCreator<any>;
 }

 export const actionCreators: GlobalActionCreators = {
   modalOpened: createAction(MODAL_OPENED),
   modalClosed: createAction(MODAL_CLOSED),
   pageOpened: createAction(PAGE_OPENED),
   pageClosed: createAction(PAGE_CLOSED),
   setUpdateAvailable: createAction(SET_UPDATE_AVAILABLE),
   setNetworkOffline: createAction(SET_NETWORK_OFFLINE),
   setNetworkOnline: createAction(SET_NETWORK_ONLINE),
 }

/**
 * Type / Utils
 */

 export interface GlobalState {
   modalOpened: boolean;
   pageOpened: boolean;
   updateAvailable: boolean;
   isOffline: boolean;
 }

 /**
  * Reducers
  */

  const initialState: GlobalState = {
    modalOpened: false,
    pageOpened: false,
    updateAvailable: false,
    isOffline: false,
  }

  export default handleActions({
    [MODAL_OPENED]: (state: GlobalState): GlobalState => ({
      ...state,
      modalOpened: true
    }),
    [MODAL_CLOSED]: (state: GlobalState): GlobalState => ({
      ...state,
      modalOpened: false
    }),

    
    [PAGE_OPENED]: (state: GlobalState): GlobalState => ({
      ...state,
      pageOpened: true
    }),
    [PAGE_CLOSED]: (state: GlobalState): GlobalState => ({
      ...state,
      pageOpened: false
    }),


    [SET_UPDATE_AVAILABLE]: (state: GlobalState): GlobalState => ({
      ...state,
      updateAvailable: true,
    }),
    [SET_NETWORK_OFFLINE]: (state: GlobalState): GlobalState => ({
      ...state,
      isOffline: true,
    }),
    [SET_NETWORK_ONLINE]: (state: GlobalState): GlobalState => ({
      ...state,
      isOffline: false,
    }),
  }, initialState)