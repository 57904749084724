import { combineReducers } from 'redux';
import global from './global';
import hr from './hr';
import auth from './auth';
import user from './user';

export default combineReducers({
  global,
  auth,
  user,
  hr,
});
