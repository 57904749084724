import { ActionCreator } from 'redux';
import { ActionType } from '@redux-saga/types';
import { createAction, handleActions } from 'redux-actions';
import { UserState } from './user';

/**
 * actions
 */

export const REQUEST_SIGNIN: ActionType = 'auth/REQUEST_SIGNIN';
const SIGNIN_SUCCESS: ActionType = 'auth/SIGNIN_SUCCESS';
const SIGNIN_FAILURE: ActionType = 'auth/SIGNIN_FAILURE';
export const REQUEST_SIGNOUT: ActionType = 'auth/REQUEST_SIGNOUT';
const SIGNOUT_SUCCESS: ActionType = 'auth/SIGNOUT_SUCCESS';
const SIGNOUT_FAILURE: ActionType = 'auth/SIGNOUT_FAILURE';

const CLEAR_ERROR: ActionType = 'auth/CLEAR_ERROR';

export type AuthActionCreators = {
  requestSignin: ActionCreator<any>,
  signinSuccess: ActionCreator<any>,
  signinFailure: ActionCreator<any>,
  requestSignout: ActionCreator<any>,
  signoutSuccess: ActionCreator<any>,
  signoutFailure: ActionCreator<any>,
  clearError: ActionCreator<any>;
}

export const actionCreators: AuthActionCreators = {
  requestSignin: createAction(REQUEST_SIGNIN),
  signinSuccess: createAction(SIGNIN_SUCCESS),
  signinFailure: createAction(SIGNIN_FAILURE),
  requestSignout: createAction(REQUEST_SIGNOUT),
  signoutSuccess: createAction(SIGNOUT_SUCCESS),
  signoutFailure: createAction(SIGNOUT_FAILURE),
  clearError: createAction(CLEAR_ERROR)
}

/**
 * constants
 */

export interface AuthState {
  isRequested: boolean,
  loading: boolean,
  error: boolean,
  errorMessage: string,
}

export interface SigninSuccessAction {
  type: string | number | symbol,
  payload: {
    user: UserState,
  }
};

export interface SigninFailureAction {
  type: string | number | symbol,
  payload: {
    errorMessage?: string,
  }
}

/**
 * reducers
 */

const initialState: AuthState = {
  isRequested: false,
  loading: false,
  error: false,
  errorMessage: '',
}

export default handleActions({
  [REQUEST_SIGNIN]: (state: AuthState): AuthState => ({
    ...state,
    isRequested: true,
    loading: true,
    error: false,
  }),
  [SIGNIN_SUCCESS]: (state: AuthState, action: SigninSuccessAction): AuthState => ({
    ...state,
    isRequested: true,
    loading: false,
    error: false,
  }),
  [SIGNIN_FAILURE]: (state: AuthState, action: SigninFailureAction): AuthState => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.payload.errorMessage,
  }),
  [REQUEST_SIGNOUT]: (state: AuthState): AuthState => ({
    ...state,
    isRequested: true,
    loading: true,
    error: false,
  }),
  [SIGNOUT_SUCCESS]: (state: AuthState): AuthState => ({
    ...state,
    loading: false,
    error: false,
  }),
  [SIGNOUT_FAILURE]: (state: AuthState): AuthState => ({
    ...state,
    loading: false,
    error: true,
  }),
  [CLEAR_ERROR]: (state: AuthState): AuthState => ({
    ...state,
    error: false,
  })
}, initialState);