import React, { useEffect, useState } from 'react';
import MdRefresh from 'react-ionicons/lib/MdRefresh';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import fb from 'lib/firebase';
import Alert from 'components/common/Alert';
import { Login } from 'pages';
import { State } from 'store';
import { isSameUser, getUserFromFBUser } from 'store/module/user';
import { UserActions } from 'store/actionCreators';
import { User } from 'entity/user';

interface Props {
  user: User;
  error: boolean;
  errorMessage: string;
}

const AuthContainer: React.FC<Props> = ({
  user,
  error,
  errorMessage,
  children
}) => {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(!!user.uid);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    // Waiting for Firebase auto initializing
    fb.auth().onAuthStateChanged(changedUser => {
      setIsLoaded(true);
      if (changedUser) {
        const newUser: User = getUserFromFBUser(changedUser);
        !isSameUser(user, newUser) && UserActions.setUser({ user: newUser });
        setIsSignedIn(true);
      }
    });
  });

  if (!isLoaded) {
    return (
      <div
        style={{
          width: '100vw',
          height: '84vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <MdRefresh fontSize='32px' color='#0084dd' rotate={true} />
      </div>
    );
  }

  return (
    <>
      {isSignedIn ? children : <Route component={Login} />}
      <Alert message={errorMessage} show={error} />
    </>
  );
};

export default connect(({ auth, user }: State) => ({
  user: user.currentUser,
  error: auth.error,
  errorMessage: auth.errorMessage
}))(AuthContainer);
