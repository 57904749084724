import rootSaga from "./saga";
import { UserState } from "./module/user";
import { AuthState } from './module/auth';
import { HrState } from "./module/hr";
import { GlobalState } from './module/global';

import configure from './configure';

export interface State {
  auth?: AuthState,
  user?: UserState,
  hr?: HrState,
  global?: GlobalState,
}

const { store, sagaMiddleware } = configure();
sagaMiddleware.run(rootSaga);

export default store;
