import { HrType } from 'entity/Hr';
import { post, get } from './request';

const HR_API_GATEWAY_URL = 'https://id2dc0v99i.execute-api.ap-northeast-2.amazonaws.com/finda-hr-record-system';

export interface RecordHistoryRequest {
  userId: string;
  email: string;
  name: string;
  hrType: HrType;
}

// POST
const RecordsHistoryType = 'RECORD_HISTORY';

// GET
const GetRecordByMonthType = 'get-record-by-month';
const GetRecordsByDateType = 'get-record-by-date';

export const recordHistory = ({ userId, email, name, hrType  }: RecordHistoryRequest) => {
  return post(HR_API_GATEWAY_URL + '/hr/record', {
    userId,
    email,
    name,
    hrType,
    type: RecordsHistoryType,
  });
}
export const fetchRecordsByDate = async (userId: string, date: string) => {
  return get(HR_API_GATEWAY_URL + `/hr/record/${date}/${userId}`, GetRecordsByDateType);
}

export const fetchRecordsByMonth = async (userId: string, date: string) => {
  return get(HR_API_GATEWAY_URL + `/hr/record/${date}/${userId}`, GetRecordByMonthType);
}

export default {
  recordHistory,
  fetchRecordsByDate,
  fetchRecordsByMonth
};