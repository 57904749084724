export const post = (url: string, data) => {
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(data)
  })
    .then(res => res.clone().json())
    .catch(err => {
      console.error(`Error from - ${url}\n\n`, err);
      throw Error;
    });
};

export const get = (url: string, requestType) => {
  const headers = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json;charset=UTF-8',
    'Request-Type': requestType
  };
  return fetch(url, {
    headers,
    method: 'GET',
    mode: 'cors'
  })
    .then(res => res.clone().json())
    .catch(err => {
      console.error(`Error from - ${url}\n\n`, err);
      throw Error;
    });
};
