import fb from "lib/firebase";

export const requestSignin = () => {
  const provider = new fb.auth.GoogleAuthProvider();

  fb.auth().useDeviceLanguage();
  // https://developers.google.com/identity/protocols/OpenIDConnect#authenticationuriparameters
  provider.setCustomParameters({
    login_hint: "user@example.com",
    prompt: 'select_account'
  });

  return fb.auth().signInWithPopup(provider);
}

export const requestSignOut = () => {
  return fb.auth().signOut();
}