import moment from 'moment';
import { RecordOfDay } from 'store/module/hr';
import { getKoreanDateFromSqlDatetime } from 'lib/utils/DateUtils';

export const getRecordOfDayFromResponse = (res: {
  attendance: string;
  leave: string;
}): RecordOfDay => ({
  // 아직 api로부터 type을 받아오지 않기때문에 ㅜ 함수 따로 선언
  attendance: res.attendance
    ? {
        hrType: null,
        insertTime: getKoreanDateFromSqlDatetime(res.attendance).getTime()
      }
    : undefined,
  leave: res.leave
    ? {
        hrType: null,
        insertTime: getKoreanDateFromSqlDatetime(res.leave).getTime()
      }
    : undefined
});

export const getRecordMapFromArray = (
  records: RecordOfDay[]
): Map<string, RecordOfDay> => {
  const prevRecordMap = new Map<string, RecordOfDay>();
  records.forEach(record => {
    if (!record.attendance) return;
    // 출근하고 퇴근 안하는건 가능하지만 출근 안하고 퇴근 하는건 불가능. 따라서 attendance만 체크
    const { insertTime } = record.attendance;
    insertTime &&
      prevRecordMap.set(moment(insertTime).format("YYYYMMDD"), record);
  });

  return prevRecordMap;
};
