import { fork, all } from "redux-saga/effects";
import { watchRequestSignin, watchRequestSignout } from "./auth";
import { watchRequestFetchTodayRecord, watchRecordHistory, watchRequestRecordsByDate } from "./hr";

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export default function* root() {
  yield all([
    fork(watchRequestSignin),
    fork(watchRequestSignout),
    fork(watchRequestFetchTodayRecord),
    fork(watchRecordHistory),
    fork(watchRequestRecordsByDate),
  ]);
}