import { put, call, take, fork, delay } from 'redux-saga/effects';
import * as AuthApi from 'lib/api/auth';
import { REQUEST_SIGNIN, REQUEST_SIGNOUT } from '../module/auth';
import { getUserFromFBUser } from '../module/user';
import { User } from 'entity/user';

function* signin() {
  // 제너레이터 함수 밖에서 호출시 store가 생성되기 전에 actionCreator가 먼저 생성됨.
  // store가 생성되기 전 dispatch는 undefined인 상태이기 때문에 actionCreator가 제대로 생성되지 않음.
  const { AuthActions, UserActions } = require('store/actionCreators');

  try {
    const { user }: firebase.auth.UserCredential = yield call(
      AuthApi.requestSignin
    );
    if (!user.email.includes('@finda.co.kr')) throw Error;
    const newUser: User = getUserFromFBUser(user);

    yield put(UserActions.setUser({ user: newUser }));
    yield put(AuthActions.signinSuccess());
  } catch (error) {
    yield put(AuthActions.signinFailure({ errorMessage: '서버로부터 정상적인 응답을 받지 못했습니다. 다시 시도해주세요.' }));
    yield delay(3000);
    yield put(AuthActions.clearError());
  }
}

function* signout() {
  const { AuthActions } = require('store/actionCreators');

  try {
    yield call(AuthApi.requestSignOut);
    yield put(AuthActions.signoutSuccess());
    window.location.reload();
  } catch (error) {
    yield put(AuthActions.signoutFailure({ errorMessage: error.message }));
  }
}

export function* watchRequestSignin() {
  while (true) {
    yield take(REQUEST_SIGNIN);
    yield fork(signin);
  }
}

export function* watchRequestSignout() {
  while (true) {
    yield take(REQUEST_SIGNOUT);
    yield fork(signout);
  }
}
