import moment from 'moment';
import { ActionType } from '@redux-saga/types';
import { ActionCreator } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import { HrType } from 'entity/Hr';

/**
 * Actions
 */

export const FETCH_TODAY_RECORD: ActionType = 'hr/FETCH_HR_RECORD';
export const FETCH_TODAY_RECORD_SUCCESS: ActionType = 'hr/FETCH_TODAY_RECORD_SUCCESS';
export const FETCH_TODAY_RECORD_FAILURE: ActionType = 'hr/FETCH_TODAY_RECORD_FAILURE';

export const SET_TODAY_RECORD: ActionType = 'hr/SET_TODAY_RECORD';

export const RECORD_HISTORY: ActionType = 'hr/RECORD_HISTORY';
export const RECORD_HISTORY_SUCCESS: ActionType = 'hr/RECORD_HISTORY_SUCCESS';
export const RECORD_HISTORY_FAILURE: ActionType = 'hr/RECORD_HISTORY_FAILURE';

export const GET_RECORDS: ActionType = 'hr/GET_RECORDS';
export const GET_RECORDS_SUCCESS: ActionType = 'hr/GET_RECORDS_SUCCESS';
export const GET_RECORDS_FAILURE: ActionType = 'hr/GET_RECORDS_BY_MONTH_FAILURE';

export const SET_PREV_RECORDS: ActionType = 'hr/SET_PREV_RECORDS';

export const REMOVE_MODAL_MESSAGE: ActionType = 'hr/REMOVE_MODAL_MESSAGE';

export type HrActionCreators = {
  fetchTodayRecord: ActionCreator<any>
  fetchTodayRecordSuccess: ActionCreator<any>
  fetchTodayRecordFailure: ActionCreator<any>

  setTodayRecord: ActionCreator<any>

  recordHistory: ActionCreator<any>
  recordHistorySuccess: ActionCreator<any>
  recordHistoryFailure: ActionCreator<any>

  getRecords: ActionCreator<any>
  getRecordsSuccess: ActionCreator<any>
  getRecordsFailure: ActionCreator<any>

  setPrevRecords: ActionCreator<any>

  removeModalMessgae: ActionCreator<any>
}

export const actionCreators: HrActionCreators = {
  fetchTodayRecord: createAction(FETCH_TODAY_RECORD),
  fetchTodayRecordSuccess: createAction(FETCH_TODAY_RECORD_SUCCESS),
  fetchTodayRecordFailure: createAction(FETCH_TODAY_RECORD_FAILURE),

  setTodayRecord: createAction(SET_TODAY_RECORD),

  recordHistory: createAction(RECORD_HISTORY),
  recordHistorySuccess: createAction(RECORD_HISTORY_SUCCESS),
  recordHistoryFailure: createAction(RECORD_HISTORY_FAILURE),

  getRecords: createAction(GET_RECORDS),
  getRecordsSuccess: createAction(GET_RECORDS_SUCCESS),
  getRecordsFailure: createAction(GET_RECORDS_FAILURE),

  setPrevRecords: createAction(SET_PREV_RECORDS),

  removeModalMessgae: createAction(REMOVE_MODAL_MESSAGE),
}

/**
 * Constants
 */

export type HrRecordState = {
  insertTime: number; // timestamp
  hrType: number;
}

export type RecordOfDay = {
  attendance: HrRecordState, 
  leave: HrRecordState
}

export interface HrState {
  todayRecords: RecordOfDay,
  loading: boolean;
  isFetched: boolean;
  errorMessage?: string;
  successMessage?: string;
  fetchedMonth: string[];
  prevRecords: {
    isFetched: boolean;
    loading: boolean;
    recordMap: Map<string, RecordOfDay>
  }
}

export interface SetHrRecordAction {
  type: string | number | symbol,
  payload: {
    todayRecords: RecordOfDay,
  }
}

export interface RecordHistoryAction {
  type: string | number | symbol,
  payload: {
    hrType: HrType
  }
}

export interface RecordHistorySuccessAction {
  type: string | number | symbol,
  payload: {
    successMessage: string,
  }
} 

export interface GetRecordsAction {
  type: string | number | symbol,
  payload: {
    date: moment.Moment,
    type: 'date' | 'month',
  }
}

export interface GetRecordsSuccessAction {
  type: string | number | symbol,
  payload: {
    successMessage: string,
  }
}

export interface FailureAction {
  type: string | number | symbol,
  payload: {
    errorMessage: string,
  }
}

export interface SetPrevRecordsAction {
  type: string | number | symbol,
  payload: {
    prevRecordMap: Map<string, RecordOfDay>,
    type: 'month' | 'date',
    month?: string, // 'YYYYMM
  }
}

/**
 * Reducers
 */

// const recordInitialState: HrRecordState = {
//   hrType: undefined,
//   insertTime: undefined,
// }

const initialState: HrState = {
  todayRecords: undefined,
  loading: false,
  errorMessage: undefined,
  successMessage: undefined,
  isFetched: false,
  fetchedMonth: [],
  prevRecords: {
    isFetched: false,
    loading: false,
    recordMap: new Map<string, RecordOfDay>(),
  }
}

export default handleActions({
  /***************** *****************/
  [FETCH_TODAY_RECORD]: (state: HrState): HrState => ({
    ...state,
    loading: true,
    isFetched: false,
  }),
  [FETCH_TODAY_RECORD_SUCCESS]: (state: HrState): HrState => ({
    ...state,
    loading: false,
    isFetched: true,
    errorMessage: undefined,
  }),
  [FETCH_TODAY_RECORD_FAILURE]: (state: HrState, action): HrState => ({
    ...state,
    loading: false,
    errorMessage: action.payload.errorMessage,
  }),

  /***************** *****************/
  [SET_TODAY_RECORD]: (state: HrState, action: SetHrRecordAction): HrState => ({
    ...state,
    todayRecords: action.payload.todayRecords,
  }),

  /***************** *****************/
  [RECORD_HISTORY]: (state: HrState): HrState => ({
    ...state,
    isFetched: false,
    loading: true,
  }),
  [RECORD_HISTORY_SUCCESS]: (state: HrState, action: RecordHistorySuccessAction): HrState => ({
    ...state,
    loading: false,
    isFetched: true,
    successMessage: action.payload.successMessage,
  }),
  [RECORD_HISTORY_FAILURE]: (state: HrState, action): HrState => ({
    ...state,
    loading: false,
    isFetched: true,
    errorMessage: action.payload.errorMessage,
  }),

  /***************** *****************/
  [GET_RECORDS]: (state: HrState): HrState => ({
    ...state,
    prevRecords: {
      ...state.prevRecords,
      loading: true,
    }
  }),
  [GET_RECORDS_SUCCESS]: (state: HrState, action: GetRecordsSuccessAction): HrState => ({
    ...state,
    // successMessage: action.payload.successMessage,
    prevRecords: { 
      ...state.prevRecords,
      isFetched: true,
      loading: false,
    }
  }),
  [GET_RECORDS_FAILURE]: (state: HrState, action: FailureAction): HrState => ({
    ...state,
    errorMessage: action.payload.errorMessage,
    prevRecords: {
      ...state.prevRecords,
      loading: false,
    }
  }),

  /***************** *****************/
  [SET_PREV_RECORDS]: (state: HrState, action: SetPrevRecordsAction): HrState => {

    const newFetchedMonth = action.payload.month 
      ? [...state.fetchedMonth, action.payload.month] 
      : state.fetchedMonth;
    const newRecordMap = state.prevRecords.recordMap;
    action.payload.prevRecordMap.forEach((value, key) => newRecordMap.set(key, value));

    return ({
      ...state,
      fetchedMonth: newFetchedMonth,
      prevRecords: {
        ...state.prevRecords,
        recordMap: newRecordMap,
      }
    })
  },

  /***************** *****************/
  [REMOVE_MODAL_MESSAGE]: (state: HrState): HrState => ({
    ...state,
    successMessage: undefined,
    errorMessage: undefined,
  })
}, initialState);