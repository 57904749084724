import moment from 'moment-timezone';

export const koreanDays: string[] = ["일", "월", "화", "수", "목", "금", "토"];

export const wordOfToday = [
  "오늘",
  "오늘",
  "오늘",
  "오늘",
  "오늘",
  "TGIF",
  "오늘",
]

export function getKoreanDate() {
  const offset = 9; // Korean offset
  const d = new Date();
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  const koreanDate = new Date(utc + 3600000 * offset);

  return koreanDate;
}

export function getDateYYYYMMDD(date: Date) {
  if (date === undefined) return undefined;
  return moment(date).tz('Asia/Seoul').format('YYYYMMDD');
}

export function get2019Holidays(): string[] {
  return [
    '0912',
    '0913',
    '1003',
    '1006',
    '1009',
    '1225',
  ]
}

export function getKoreanDateFromSqlDatetime(datetime: string): Date {
  const timestamp = Date.parse(datetime);
  const jsDate = new Date(timestamp);
  jsDate.setHours(jsDate.getHours() - 9);

  return jsDate;
}

export const isWeekend = (date: Date) => {
  return date.getDay() === 0 || date.getDay() === 6;
}

export const isHoliday = (date: Date) => {
  return get2019Holidays().indexOf(moment(date).format('MMDD')) > -1;
}