import React, { Component } from 'react';

interface WithSplittingState {
  Splitted: typeof React.Component;
}

const withSplitting = getComponent => {
  class WithSplitting extends Component<{}, WithSplittingState> {
    constructor(props) {
      super(props);
      this.state = {
        Splitted: null,
      };

      getComponent().then(({ default: Splitted }) => {
        this.setState({
          Splitted,
        });
      });
    }

    render() {
      const { Splitted } = this.state;
      if (Splitted === null) return null;
      return <Splitted {...this.props} />
    }
  }

  return WithSplitting;
}

export default withSplitting;