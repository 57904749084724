import { bindActionCreators } from 'redux';
import store from './index';
import { actionCreators as authActions, AuthActionCreators } from './module/auth';
import { actionCreators as userActions, UserActionCreators } from './module/user';
import { actionCreators as hrActions, HrActionCreators } from './module/hr';
import { actionCreators as globalActions, GlobalActionCreators } from './module/global';

const dispatch = store && store.dispatch;

console.log('actionCreator 생성~ ', dispatch);

export const AuthActions: AuthActionCreators = bindActionCreators(authActions, dispatch);
export const HrActions: HrActionCreators = bindActionCreators(hrActions, dispatch);
export const UserActions: UserActionCreators = bindActionCreators(userActions, dispatch);
export const GlobalActions: GlobalActionCreators = bindActionCreators(globalActions, dispatch);